ol {
    margin: 0;
    padding: 0;
}

table td,
table th {
    padding: 0;
}

.c18 {
    padding-top: 0.6pt;
    text-indent: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 1.2pt;
}

.c28 {
    padding-top: 0.6pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 11.6pt;
    height: 11pt;
}

.c15 {
    padding-top: 15.1pt;
    text-indent: 0.2pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 7.2pt;
}

.c6 {
    padding-top: 15.1pt;
    text-indent: 0.2pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 29.7pt;
}

.c3 {
    padding-top: 0pt;
    text-indent: -0.3pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 10.7pt;
}

.c25 {
    padding-top: 1.7pt;
    text-indent: -0.5pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 1pt;
}

.c24 {
    padding-top: 0.6pt;
    text-indent: -0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 17.6pt;
}

.c7 {
    padding-top: 0pt;
    text-indent: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 21.4pt;
}

.c0 {
    padding-top: 1.7pt;
    text-indent: 0.8pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 0.4pt;
}

.c27 {
    padding-top: 15.1pt;
    text-indent: -0pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 18pt;
}

.c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal;
}

.c16 {
    padding-top: 15.1pt;
    text-indent: 1pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 1.2pt;
}

.c10 {
    padding-top: 1.7pt;
    text-indent: 0.8pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 2.5pt;
}

.c8 {
    padding-top: 15.1pt;
    text-indent: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 37.2pt;
}

.c19 {
    padding-top: 1.7pt;
    text-indent: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 31.2pt;
}

.c23 {
    padding-top: 0.6pt;
    text-indent: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 3.7pt;
}

.c1 {
    padding-top: 1.7pt;
    text-indent: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 11.8pt;
}

.c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal;
}

.c4 {
    padding-top: 1.7pt;
    text-indent: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 3.9pt;
}

.c13 {
    padding-top: 1.7pt;
    text-indent: 1pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 1.9pt;
}

.c22 {
    padding-top: 15.1pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 3.9pt;
}

.c17 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt;
}

.c14 {
    padding-top: 16.2pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
}

.c11 {
    padding-top: 15.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
}

.c20 {
    padding-top: 16.2pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
}

.c26 {
    padding-top: 15.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
}

.c12 {
    padding-top: 15.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
}

.c21 {
    background-color: #ffffff;
    max-width: 541.5pt;
    padding: 0pt 70.5pt 0pt 0pt;
}

.c9 {
    color: #000000;
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
}

h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}
