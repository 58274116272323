@import url(https://themes.googleusercontent.com/fonts/css?kit=ieFBY7O4903VybURyt0ZdqX2vk9mRuy5rDNEzeXsDOY);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ol, table td, table th {
    margin: 0;
    padding: 0;
}

ol {
    list-style-type: none;
}

li {
    counter-increment: list-counter;
}

li.li-bullet-0:before,
li.li-bullet-1:before {
    white-space: nowrap;
    display: inline-block;
}

li.li-bullet-0:before {
    margin-left: -18pt;
    min-width: 18pt;
}

li.li-bullet-1:before {
    margin-left: -13.5pt;
    min-width: 13.5pt;
}

.c0 {
    font-family: "Arial";
    font-size: 11pt;
    font-weight: 700;
}

.c1,
.c37 {
    font-family: "Arial";
    font-size: 11pt;
    font-weight: 400;
}

.c3 {
    color: #000000;
    font-family: "Times New Roman";
    font-size: 12pt;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
}

.c12 {
    color: #202124;
    font-family: "Roboto";
    font-size: 10.5pt;
    font-weight: 400;
}

.c2,
.c5,
.c8,
.c20,
.c22,
.c31 {
    line-height: 1.0;
    orphans: 2;
    widows: 2;
}

.c2 {
    text-align: center;
}

.c5 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    text-align: left;
    height: 12pt;
}

.c8,
.c20,
.c31 {
    text-align: justify;
}

.c22 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    text-align: left;
}

.c31 {
    padding-bottom: 6pt;
}

.c9,
.c6 {
    text-decoration: underline;
}

.c30,
.c38 {
    text-decoration: line-through;
}

ol {
    margin: 0;
    padding: 0;
}

table td,
table th {
    padding: 0;
}

.c18 {
    padding-top: 0.6pt;
    text-indent: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 1.2pt;
}

.c28 {
    padding-top: 0.6pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 11.6pt;
    height: 11pt;
}

.c15 {
    padding-top: 15.1pt;
    text-indent: 0.2pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 7.2pt;
}

.c6 {
    padding-top: 15.1pt;
    text-indent: 0.2pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 29.7pt;
}

.c3 {
    padding-top: 0pt;
    text-indent: -0.3pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 10.7pt;
}

.c25 {
    padding-top: 1.7pt;
    text-indent: -0.5pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 1pt;
}

.c24 {
    padding-top: 0.6pt;
    text-indent: -0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 17.6pt;
}

.c7 {
    padding-top: 0pt;
    text-indent: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 21.4pt;
}

.c0 {
    padding-top: 1.7pt;
    text-indent: 0.8pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 0.4pt;
}

.c27 {
    padding-top: 15.1pt;
    text-indent: -0pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 18pt;
}

.c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal;
}

.c16 {
    padding-top: 15.1pt;
    text-indent: 1pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 1.2pt;
}

.c10 {
    padding-top: 1.7pt;
    text-indent: 0.8pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 2.5pt;
}

.c8 {
    padding-top: 15.1pt;
    text-indent: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 37.2pt;
}

.c19 {
    padding-top: 1.7pt;
    text-indent: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 31.2pt;
}

.c23 {
    padding-top: 0.6pt;
    text-indent: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 3.7pt;
}

.c1 {
    padding-top: 1.7pt;
    text-indent: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 11.8pt;
}

.c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal;
}

.c4 {
    padding-top: 1.7pt;
    text-indent: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 3.9pt;
}

.c13 {
    padding-top: 1.7pt;
    text-indent: 1pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 1.9pt;
}

.c22 {
    padding-top: 15.1pt;
    padding-bottom: 0pt;
    line-height: 1.0999999999999999;
    text-align: left;
    margin-right: 3.9pt;
}

.c17 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt;
}

.c14 {
    padding-top: 16.2pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
}

.c11 {
    padding-top: 15.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
}

.c20 {
    padding-top: 16.2pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
}

.c26 {
    padding-top: 15.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
}

.c12 {
    padding-top: 15.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
}

.c21 {
    background-color: #ffffff;
    max-width: 541.5pt;
    padding: 0pt 70.5pt 0pt 0pt;
}

.c9 {
    color: #000000;
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
}

h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

/*ol {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

/*table td, table th {*/
/*    padding: 0;*/
/*}*/

/*.c2 {*/
/*    background-color: #ffffff;*/
/*    padding-top: 12pt;*/
/*    border-top-width: 0pt;*/
/*    padding-bottom: 0pt;*/
/*    line-height: 1.1500000000000001;*/
/*    orphans: 2;*/
/*    border-top-style: solid;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*    height: 11pt;*/
/*}*/

/*.c0 {*/
/*    background-color: #ffffff;*/
/*    padding-top: 0pt;*/
/*    border-bottom-width: 0pt;*/
/*    padding-bottom: 12pt;*/
/*    line-height: 1.1500000000000001;*/
/*    border-bottom-style: solid;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

/*.c4 {*/
/*    background-color: #ffffff;*/
/*    padding-top: 12pt;*/
/*    border-top-width: 0pt;*/
/*    padding-bottom: 0pt;*/
/*    line-height: 1.1500000000000001;*/
/*    orphans: 2;*/
/*    border-top-style: solid;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

/*.c1 {*/
/*    color: #151515;*/
/*    font-weight: 400;*/
/*    text-decoration: none;*/
/*    vertical-align: baseline;*/
/*    font-size: 10.5pt;*/
/*    font-family: "Arial";*/
/*    font-style: normal;*/
/*}*/

/*.c14 {*/
/*    color: #000000;*/
/*    font-weight: 400;*/
/*    text-decoration: none;*/
/*    vertical-align: baseline;*/
/*    font-size: 11pt;*/
/*    font-family: "Arial";*/
/*    font-style: normal;*/
/*}*/

/*.c11 {*/
/*    padding-top: 0pt;*/
/*    padding-bottom: 0pt;*/
/*    line-height: 1.1500000000000001;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*    height: 11pt;*/
/*}*/

/*.c5 {*/
/*    color: #151515;*/
/*    font-weight: 700;*/
/*    text-decoration: none;*/
/*    vertical-align: baseline;*/
/*    font-size: 15pt;*/
/*    font-family: "Arial";*/
/*    font-style: normal;*/
/*}*/

/*.c13 {*/
/*    background-color: #ffffff;*/
/*    padding-top: 0pt;*/
/*    padding-bottom: 0pt;*/
/*    line-height: 1.1500000000000001;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

/*.c3 {*/
/*    color: #151515;*/
/*    font-weight: 700;*/
/*    text-decoration: none;*/
/*    vertical-align: baseline;*/
/*    font-size: 10.5pt;*/
/*    font-family: "Arial";*/
/*    font-style: normal;*/
/*}*/

/*.c8 {*/
/*    font-weight: 400;*/
/*    text-decoration: none;*/
/*    vertical-align: baseline;*/
/*    font-family: "Arial";*/
/*    font-style: italic;*/
/*}*/

/*.c9 {*/
/*    background-color: #ffffff;*/
/*    max-width: 468pt;*/
/*    padding: 72pt 72pt 72pt 72pt;*/
/*}*/

/*.c6 {*/
/*    color: #151515;*/
/*    font-size: 10.5pt;*/
/*}*/

/*.c10 {*/
/*    font-style: italic;*/
/*}*/

/*.c7 {*/
/*    font-weight: 700;*/
/*}*/

/*.c12 {*/
/*    background-color: #ffff00;*/
/*}*/

/*.title {*/
/*    padding-top: 0pt;*/
/*    color: #000000;*/
/*    font-size: 26pt;*/
/*    padding-bottom: 3pt;*/
/*    font-family: "Arial";*/
/*    line-height: 1.1500000000000001;*/
/*    page-break-after: avoid;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

/*.subtitle {*/
/*    padding-top: 0pt;*/
/*    color: #666666;*/
/*    font-size: 15pt;*/
/*    padding-bottom: 16pt;*/
/*    font-family: "Arial";*/
/*    line-height: 1.1500000000000001;*/
/*    page-break-after: avoid;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

/*li {*/
/*    color: #000000;*/
/*    font-size: 11pt;*/
/*    font-family: "Arial";*/
/*}*/

/*p {*/
/*    margin: 0;*/
/*    color: #000000;*/
/*    font-size: 11pt;*/
/*    font-family: "Arial";*/
/*}*/

/*h1 {*/
/*    padding-top: 20pt;*/
/*    color: #000000;*/
/*    font-size: 20pt;*/
/*    padding-bottom: 6pt;*/
/*    font-family: "Arial";*/
/*    line-height: 1.1500000000000001;*/
/*    page-break-after: avoid;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

/*h2 {*/
/*    padding-top: 18pt;*/
/*    color: #000000;*/
/*    font-size: 16pt;*/
/*    padding-bottom: 6pt;*/
/*    font-family: "Arial";*/
/*    line-height: 1.1500000000000001;*/
/*    page-break-after: avoid;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

/*h3 {*/
/*    padding-top: 16pt;*/
/*    color: #434343;*/
/*    font-size: 14pt;*/
/*    padding-bottom: 4pt;*/
/*    font-family: "Arial";*/
/*    line-height: 1.1500000000000001;*/
/*    page-break-after: avoid;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

/*h4 {*/
/*    padding-top: 14pt;*/
/*    color: #666666;*/
/*    font-size: 12pt;*/
/*    padding-bottom: 4pt;*/
/*    font-family: "Arial";*/
/*    line-height: 1.1500000000000001;*/
/*    page-break-after: avoid;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

/*h5 {*/
/*    padding-top: 12pt;*/
/*    color: #666666;*/
/*    font-size: 11pt;*/
/*    padding-bottom: 4pt;*/
/*    font-family: "Arial";*/
/*    line-height: 1.1500000000000001;*/
/*    page-break-after: avoid;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

/*h6 {*/
/*    padding-top: 12pt;*/
/*    color: #666666;*/
/*    font-size: 11pt;*/
/*    padding-bottom: 4pt;*/
/*    font-family: "Arial";*/
/*    line-height: 1.1500000000000001;*/
/*    page-break-after: avoid;*/
/*    font-style: italic;*/
/*    orphans: 2;*/
/*    widows: 2;*/
/*    text-align: left;*/
/*}*/

