@import url(https://themes.googleusercontent.com/fonts/css?kit=ieFBY7O4903VybURyt0ZdqX2vk9mRuy5rDNEzeXsDOY);

ol, table td, table th {
    margin: 0;
    padding: 0;
}

ol {
    list-style-type: none;
}

li {
    counter-increment: list-counter;
}

li.li-bullet-0:before,
li.li-bullet-1:before {
    white-space: nowrap;
    display: inline-block;
}

li.li-bullet-0:before {
    margin-left: -18pt;
    min-width: 18pt;
}

li.li-bullet-1:before {
    margin-left: -13.5pt;
    min-width: 13.5pt;
}

.c0 {
    font-family: "Arial";
    font-size: 11pt;
    font-weight: 700;
}

.c1,
.c37 {
    font-family: "Arial";
    font-size: 11pt;
    font-weight: 400;
}

.c3 {
    color: #000000;
    font-family: "Times New Roman";
    font-size: 12pt;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
}

.c12 {
    color: #202124;
    font-family: "Roboto";
    font-size: 10.5pt;
    font-weight: 400;
}

.c2,
.c5,
.c8,
.c20,
.c22,
.c31 {
    line-height: 1.0;
    orphans: 2;
    widows: 2;
}

.c2 {
    text-align: center;
}

.c5 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    text-align: left;
    height: 12pt;
}

.c8,
.c20,
.c31 {
    text-align: justify;
}

.c22 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    text-align: left;
}

.c31 {
    padding-bottom: 6pt;
}

.c9,
.c6 {
    text-decoration: underline;
}

.c30,
.c38 {
    text-decoration: line-through;
}
